var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      staticClass: "search_member-popup",
      attrs: { title: "Search & Invite Members" },
      on: { cancel: _vm.close },
      model: {
        value: _vm.isVisible,
        callback: function ($$v) {
          _vm.isVisible = $$v
        },
        expression: "isVisible",
      },
    },
    [
      _c(
        "a-row",
        { attrs: { type: "flex", justify: "space-between", gutter: 1 } },
        [
          _c(
            "a-col",
            { attrs: { xs: 17 } },
            [
              _c("a-input", {
                attrs: { type: "email", placeholder: "Enter members email" },
                model: {
                  value: _vm.keyword,
                  callback: function ($$v) {
                    _vm.keyword = $$v
                  },
                  expression: "keyword",
                },
              }),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { xs: 6 } },
            [
              _c(
                "a-button",
                {
                  attrs: { block: "", type: "primary" },
                  on: { click: _vm.searchEmails },
                },
                [_vm._v("Search")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        _vm._l(_vm.members, function (member) {
          return _c(
            "a-row",
            { key: member.user_id, attrs: { type: "flex", gutter: 4 } },
            [
              _c("a-col", { attrs: { span: 6 } }, [
                _vm._v(
                  "\n        " +
                    _vm._s(member.first_name) +
                    " " +
                    _vm._s(member.last_name) +
                    "..\n      "
                ),
              ]),
              _c("a-col", { attrs: { span: 8 } }, [
                _vm._v("\n        " + _vm._s(member.work_email) + "\n      "),
              ]),
              _c(
                "a-col",
                { staticClass: "gx-text-right", attrs: { span: 10 } },
                [
                  !member.status || member.status === "decline"
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            block: "",
                            size: "small",
                            loading: _vm.loader && _vm.selectId === member.id,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.inviteMember(member.id)
                            },
                          },
                        },
                        [_vm._v("\n          Send Invite\n        ")]
                      )
                    : _vm._e(),
                  member.status === "invite" ||
                  member.status === "accept" ||
                  member.status === "archive"
                    ? _c(
                        "a-button",
                        {
                          staticStyle: {
                            "margin-bottom": "5px",
                            "margin-right": "0",
                          },
                          attrs: {
                            type: "danger",
                            size: "small",
                            loading: _vm.loader && _vm.selectId === member.id,
                          },
                        },
                        [
                          member.status === "invite"
                            ? [_vm._v("Invitation Pending")]
                            : _vm._e(),
                          member.status === "archive"
                            ? [_vm._v("Archived")]
                            : _vm._e(),
                          member.status === "accept"
                            ? [_vm._v("Already a Member")]
                            : _vm._e(),
                        ],
                        2
                      )
                    : _vm._e(),
                  member.status === "invite"
                    ? _c(
                        "a-button",
                        {
                          staticClass: "gx-btn-red gx-fs-sm",
                          staticStyle: {
                            "margin-bottom": "5px",
                            "margin-right": "8px",
                          },
                          attrs: { size: "small", type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.updateClubMember(
                                member.id,
                                "inviteModal"
                              )
                            },
                          },
                        },
                        [_vm._v("Resend Invite")]
                      )
                    : _vm._e(),
                  member.status === "invite"
                    ? _c(
                        "a-button",
                        {
                          staticClass: "gx-btn-red gx-fs-sm",
                          staticStyle: { "margin-bottom": "5px" },
                          attrs: { size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.updateClubMember(
                                member.id,
                                "cancelModal"
                              )
                            },
                          },
                        },
                        [_vm._v("Cancel Invite")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
      _vm.members.length < 1 && _vm.error_msg
        ? _c("div", { staticClass: "gx-text-center" }, [
            _vm._v("\n    Member not found\n  "),
          ])
        : _vm._e(),
      _c("vue-instant-loading-spinner", { ref: "Spinner" }),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }